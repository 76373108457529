<template>
  <div>
    <van-loading type="spinner" v-if="loading" />
    <div id="top">
      <span class="title"> {{ $t("mine.mine") }}</span>
    </div>
    <div id="content">
      <div id="selfmessage">
        <img src="assets/self.png" />
        <div class="info">
          <p>
            <span>{{ name }}</span>
            <span class="icon">{{ clerkType }}</span>
          </p>
          <p>{{ storeName }}</p>
        </div>
      </div>
      <div class="card">
        <div class="pid">
          <p>{{ $t("mine.totalSale") }}</p>
          <p class="bold">{{ num }}</p>
        </div>
        <div class="pid">
          <p>{{ $t("mine.totalAmount") }}</p>
          <p class="bold">{{ money }}</p>
        </div>
      </div>
      <div class="tab">
        <div class="tabli" @click="toRecord">
          <span class="text">{{ $t("mine.Imeiorders") }}</span>
          <img
            src="assets/dayuhao2.png"
            style="height: 30rpx; width: 30rpx"
            class="abs rt-20 t-34"
          />
        </div>
        <div class="tabli" @click="handleSettleOrderClick">
          <span class="text">{{ $t("settle.settleOrder") }}</span>
          <img
            src="assets/dayuhao2.png"
            style="height: 30rpx; width: 30rpx"
            class="abs rt-20 t-34"
          />
        </div>
        <div class="tabli" @click="toBank">
          <span class="text">{{ $t("mine.Bankcard") }}</span>
          <img
            src="assets/dayuhao2.png"
            style="height: 30rpx; width: 30rpx"
            class="abs rt-20 t-34"
          />
        </div>
        <div class="tabli" @click="toSetUp">
          <span class="text">{{ $t("mine.Settings") }}</span>
          <img
            src="assets/dayuhao2.png"
            style="height: 30rpx; width: 30rpx"
            class="abs rt-20 t-34"
          />
        </div>
      </div>
    </div>
    <van-tabbar v-model="active" active-color="#07c160" inactive-color="#999">
      <van-tabbar-item  to="/home">
        <span>{{ $t("navbar.home") }}</span>
        <template #icon="props">
          <img
            :src="props.active ? 'assets/homeActive.png' : 'assets/home.png'"
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item  to="/message">
        <span>{{ $t("navbar.message") }}</span>
        <template #icon="props2">
          <img
            :src="
              props2.active ? 'assets/messageActive.png' : 'assets/message.png'
            "
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item  to="/mine">
        <span>{{ $t("navbar.mine") }}</span>
        <template #icon="props3">
          <img
            :src="props3.active ? 'assets/mineActive.png' : 'assets/mine.png'"
          />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import { count, user } from "@network/api";
export default {
  name: "",
  components: {},
  watch: {
    $route: "getPath",
  },
  data() {
    return {
      loading: false,
      name: "Lorem ipsum ",
      clerkType: "",
      storeName:
        "Kexin Communication Store, Yishan Town, Jingtai County, Baiyin City",
      num: 0,
      money: '0.00',
      active: 2,
      bankId: null,
      bankName: null,
      bankNo: null,
      bankFormatAccount: null,
    };
  },
  mounted() {
    this.getPath();
  },
  methods: {
    getPath() {
      if (this.$route.path == "/mine") {
        this.loading = true;
        this.active = 2;
        user()
          .then((res) => {
            this.loading = false;
            if(res.code === '10011'){
            return
          }
            if (res.code == "000000") {
              if (res.data.clerkType == 1) {
                this.clerkType = this.$t("mine.clerkType1");
              }else if (res.data.clerkType == 3) {
                this.clerkType = this.$t("mine.clerkType3");
              }else if (res.data.clerkType == 5) {
                this.clerkType = this.$t("mine.clerkType5");
              }
              this.storeName = res.data.storeName;
              this.name = res.data.userName;
              let bankData = {
                bankId: res.data.bankId,
                bankName: res.data.bankName,
                bankNo: res.data.bankNo,
                bankFormatAccount: res.data.bankFormatAccount,
              };
              window.localStorage.setItem("bankData", JSON.stringify(bankData));
            } else {
              this.$toast(res.message);
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$toast(err);
          });

        count()
          .then((res) => {
            if(res.code === '10011'){
            return
          }
            if (res.code == "000000") {
              if(res.data.counts){
              this.num = res.data.counts;
              }
               if(res.data.points){
              this.money = this.turnString(res.data.points);
              }else{
                this.money = '0.00';
              }
            } else {
              this.$toast(res.message);
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$toast(err);
          });
      }
    },
    turnString(value){
        var value=Math.round(parseFloat(value)*100)/100;
        var xsd=value.toString().split(".");
        if(xsd.length==1){
          value=value.toString()+".00";
          return value;
        }
        if(xsd.length>1){
          if(xsd[1].length<2){
            value=value.toString()+"0";
          }
          return value;
        }
    },
    toRecord() {
      this.$router.push({ path: "/record" });
    },
    toBank() {
      this.$router.push({ path: "/bankCount" });
    },
    handleSettleOrderClick() {
      this.$router.push({ path: "/settleOrder" });
    },
    toSetUp() {
      this.$router.push({ path: "/setting" });
    },
  },
};
</script>
<style scoped>
#top {
  background: #fafafa;
}
#content {
  background: #fafafa;
  padding: 0 24px;
  box-sizing: border-box;
}
#selfmessage {
  display: flex;
  padding: 10px 0 10px 0;
}
#selfmessage img {
  width: 56px;
  height: 56px;
}
.info {
  margin-left: 20px;
}
.info p:nth-child(1) {
  font-weight: bold;
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  color: #333;
}
.info p:nth-child(2) {
  margin: 0;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  margin-top: 5px;
  color: #999999;
}
.info .icon {
      text-align: center;
    width: auto;
    height: 16px;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #2dd070;
    background: #ccedd3;
    border-radius: 3px;
    display: inline-block;
    padding: 0px 4px;
    margin-left: 5px;
}
.card {
  color: #666;
  padding: 17px 0;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
}
.pid {
  display: flex;
  margin-right: 84rpx;
  flex-direction: column;
  width: calc(50vw - 30px);
  justify-content: center;
  /* align-items: center; */
  height: 96px;
  left: 24px;
  margin-right: 15px;
  top: 104px;
  background: #fff;
  box-sizing: border-box;
  border-radius: 12px;
  padding-left: 23px;
}
.pid:nth-child(2) {
  margin-right: 0;
}

.pid p:nth-child(1) {
  margin: 0;
  font-size: 13px;
  line-height: 18px;
  color: #999999;
  margin-bottom: 11px;
}
.pid .bold {
  margin: 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #333333;
}
.tab {
  background: #fff;
  border-radius: 10px;
  margin-top: 20px;
}
.tab .tabli {
  padding: 0 16px;
  width: 100%;
  position: relative;
  height: 48px;
  padding: 1;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
}
.tab .tabli .text {
  color: #333;
  font-size: 14px;
}
.tabli img {
  height: 16px;
  width: 16px;
}
</style>
